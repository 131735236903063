import React from "react";
import "./style.scss";

const Marque = () => {
  return (
    <div className="marque">
      <div className="marque_card">
        <p className="title">Транспорт</p>
        <p className="price">2000 сум</p>
        <span className="add">Добавить</span>
      </div>
      <div className="marque_card">
        <p className="title">Транспорт</p>
        <p className="price">2000 сум</p>
        <span className="add">Добавить</span>
      </div>
      <div className="marque_card">
        <p className="title">Транспорт</p>
        <p className="price">2000 сум</p>
        <span className="add">Добавить</span>
      </div>
      <div className="marque_card">
        <p className="title">Транспорт</p>
        <p className="price">2000 сум</p>
        <span className="add">Добавить</span>
      </div>
      <div className="marque_card">
        <p className="title">Транспорт</p>
        <p className="price">2000 сум</p>
        <span className="add">Добавить</span>
      </div>
      <div className="marque_card">
        <p className="title">Транспорт</p>
        <p className="price">2000 сум</p>
        <span className="add">Добавить</span>
      </div>
      <div className="marque_card">
        <p className="title">Транспорт</p>
        <p className="price">2000 сум</p>
        <span className="add">Добавить</span>
      </div>
      <div className="marque_card">
        <p className="title">Транспорт</p>
        <p className="price">2000 сум</p>
        <span className="add">Добавить</span>
      </div>
      <div className="marque_card">
        <p className="title">Транспорт</p>
        <p className="price">2000 сум</p>
        <span className="add">Добавить</span>
      </div>
      <div className="marque_card">
        <p className="title">Транспорт</p>
        <p className="price">2000 сум</p>
        <span className="add">Добавить</span>
      </div>
    </div>
  );
};

export default Marque;
